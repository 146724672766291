//
// Core
//

@import "../node_modules/uikit/src/less/components/_import.less";
//
// Master Theme
//

@import "master/_import.less";

//
// Tradesource Theme
//
@import "tradesource/tradesource-variables.less";

@import "common-theme.less";

// Brand Specific

// make icon buttons ts purple
@icon-button-background: @global-primary-background;
@icon-button-color: @global-background;

.uk-logo {
  img {
    //max-width: 300px;
    max-height: 60px;
  }
}

#offcanvasNav {
  .uk-logo {
    img {
      //max-width: 300px;
      max-height: 50px;
      margin-bottom: 1em;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.menu-tile.uk-card-primary {
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.menu-tile-a:hover {
  text-decoration: none;
}

.menu-tile.uk-card-primary:hover,
.menu-tile.uk-card-primary:focus {
    background-color: lighten(@global-primary-background, 10%) ;
    text-decoration-line: none;
}

.tile-menu-dropdown-option:hover {
  text-decoration-line: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
  text-decoration-color: @global-primary-background;
}

.tile-menu-dropdown-option .unavailable {
  color: lighten(gray, 30%);
}

.tile-menu-dropdown-option .unavailable:hover {
  background-color: transparent!important;
}