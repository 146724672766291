//
// Component: Progress
//
// ========================================================================


// Variables
// ========================================================================

@progress-border-radius:                        500px;


// Component
// ========================================================================

.hook-progress() when not (@progress-border-radius = 0) {
    border-radius: @progress-border-radius;
    overflow: hidden;
}

.hook-progress-bar() {}


// Miscellaneous
// ========================================================================

.hook-progress-misc() {}
