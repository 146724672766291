//
// Component: Card
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@card-border-radius:                           0;

@card-badge-border-radius:                     0;


// Component
// ========================================================================

.hook-card() when not (@card-border-radius = 0) {
    border-radius: @card-border-radius;
}


// Sections
// ========================================================================

.hook-card-body() {}

.hook-card-header() {}

.hook-card-footer() {}


// Media
// ========================================================================

.hook-card-media() when not (@card-border-radius = 0) {
    overflow: hidden;
}

.hook-card-media-top() when not (@card-border-radius = 0) {
    border-radius: @card-border-radius @card-border-radius 0 0;
}

.hook-card-media-bottom() when not (@card-border-radius = 0) {
    border-radius: 0 0 @card-border-radius @card-border-radius;
}

.hook-card-media-left() when not (@card-border-radius = 0) {
    border-radius: @card-border-radius 0 0 @card-border-radius;
}

.hook-card-media-right() when not (@card-border-radius = 0) {
    border-radius: 0 @card-border-radius @card-border-radius 0;
}


// Title
// ========================================================================

.hook-card-title() {}


// Badge
// ========================================================================

.hook-card-badge() when not (@card-badge-border-radius = 0) {
    border-radius: @card-badge-border-radius;
}


// Hover modifier
// ========================================================================

.hook-card-hover() {}


// Style modifiers
// ========================================================================

.hook-card-default() {}

.hook-card-default-title() {}

.hook-card-default-hover() {}

.hook-card-default-header() {}

.hook-card-default-footer() {}

//
// Primary
//

.hook-card-primary() {}

.hook-card-primary-title() {}

.hook-card-primary-hover() {}

//
// Secondary
//

.hook-card-secondary() {}

.hook-card-secondary-title() {}

.hook-card-secondary-hover() {}


// Miscellaneous
// ========================================================================

.hook-card-misc() {}

.hook-card-misc() when not (@nav-default-item-border-radius = 0) {

    .uk-card-body .uk-nav-default > li > a { border-radius: 0; }

}


// Inverse
// ========================================================================

.hook-inverse-card-badge() {}
