//
// Component: Card
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@card-hover-box-shadow:                        none;
@internal-card-hover-transition-box-shadow:    0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);

@card-default-box-shadow:                      none;
@card-default-hover-box-shadow:                none;

@card-primary-box-shadow:                      none;
@card-primary-hover-box-shadow:                none;

@card-secondary-box-shadow:                    none;
@card-secondary-hover-box-shadow:              none;


// Component
// ========================================================================

.hook-card() {}


// Sections
// ========================================================================

.hook-card-body() {}

.hook-card-header() {}

.hook-card-footer() {}


// Media
// ========================================================================

.hook-card-media() {}

.hook-card-media-top() {}

.hook-card-media-bottom() {}

.hook-card-media-left() {}

.hook-card-media-right() {}


// Title
// ========================================================================

.hook-card-title() {}


// Badge
// ========================================================================

.hook-card-badge() {}


// Hover modifier
// ========================================================================

.hook-card-hover() when not (@card-hover-box-shadow = none) {
    box-shadow: @card-hover-box-shadow;
}


// Style modifiers
// ========================================================================

.hook-card-default() when not (@card-default-box-shadow = none) {
    box-shadow: @card-default-box-shadow;
}

.hook-card-default-title() {}

.hook-card-default-hover() when not (@card-default-hover-box-shadow = none) {
    box-shadow: @card-default-hover-box-shadow;
}

.hook-card-default-header() {}

.hook-card-default-footer() {}

//
// Primary
//

.hook-card-primary() when not (@card-primary-box-shadow = none) {
    box-shadow: @card-primary-box-shadow;
}

.hook-card-primary-title() {}

.hook-card-primary-hover() when not (@card-primary-hover-box-shadow = none) {
    box-shadow: @card-primary-hover-box-shadow;
}

//
// Secondary
//

.hook-card-secondary() when not (@card-secondary-box-shadow = none) {
    box-shadow: @card-secondary-box-shadow;
}

.hook-card-secondary-title() {}

.hook-card-secondary-hover() when not (@card-secondary-hover-box-shadow = none) {
    box-shadow: @card-secondary-hover-box-shadow;
}


// Miscellaneous
// ========================================================================

.hook-card-misc() when not (@card-hover-box-shadow = none) {

    .uk-card-hover:not(.uk-card-default):not(.uk-card-primary):not(.uk-card-secondary) {
        box-shadow: @internal-card-hover-transition-box-shadow;
    }

}


// Inverse
// ========================================================================

.hook-inverse-card-badge() {}
