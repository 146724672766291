//
// Component: Pagination
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@pagination-item-box-shadow:                    none;
@pagination-item-hover-box-shadow:              none;
@pagination-item-active-box-shadow:             none;


// Component
// ========================================================================

.hook-pagination() {}


// Items
// ========================================================================

.hook-pagination-item() when not (@pagination-item-box-shadow = none) {
    box-shadow: @pagination-item-box-shadow;
}

.hook-pagination-item-hover() when not (@pagination-item-hover-box-shadow = none) {
    box-shadow: @pagination-item-hover-box-shadow;
}

.hook-pagination-item-active() when not (@pagination-item-active-box-shadow = none) {
    box-shadow: @pagination-item-active-box-shadow;
}

.hook-pagination-item-disabled() {}


// Miscellaneous
// ========================================================================

.hook-pagination-misc() {}


// Inverse
// ========================================================================

@inverse-pagination-item-box-shadow:            none;
@inverse-pagination-item-hover-box-shadow:      none;
@inverse-pagination-item-active-box-shadow:     none;

.hook-inverse-pagination-item() when not (@pagination-item-box-shadow = none) {
    box-shadow: @inverse-pagination-item-box-shadow;
}
.hook-inverse-pagination-item-hover() when not (@pagination-item-hover-box-shadow = none) {
    box-shadow: @inverse-pagination-item-hover-box-shadow;
}
.hook-inverse-pagination-item-active() when not (@pagination-item-active-box-shadow = none) {
    box-shadow: @inverse-pagination-item-active-box-shadow;
}
.hook-inverse-pagination-item-disabled() {}