//
// Component: Card
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@card-badge-border-mode:                        ~'';
@card-badge-border-width:                       0;
@card-badge-border:                             transparent;

@card-hover-border-mode:                        ~'';
@card-hover-border-width:                       0;
@card-hover-border:                             transparent;

@card-default-border-mode:                      ~'';
@card-default-border-width:                     0;
@card-default-border:                           transparent;
@card-default-hover-border:                     transparent;

@card-primary-border-mode:                      ~'';
@card-primary-border-width:                     0;
@card-primary-border:                           transparent;
@card-primary-hover-border:                     transparent;

@card-secondary-border-mode:                    ~'';
@card-secondary-border-width:                   0;
@card-secondary-border:                         transparent;
@card-secondary-hover-border:                   transparent;

@internal-card-border-image-slice:              ~'';
@internal-card-border-image-width:              ~'';
@internal-card-border-image-repeat:             ~'';

@internal-card-hover-border-image:              ~'';
@internal-card-default-border-image:            ~'';
@internal-card-primary-border-image:            ~'';
@internal-card-secondary-border-image:          ~'';


// Component
// ========================================================================

.hook-card() {}


// Sections
// ========================================================================

.hook-card-body() {}

.hook-card-header() {}

.hook-card-footer() {}


// Media
// ========================================================================

.hook-card-media() {}

.hook-card-media-top() {}

.hook-card-media-bottom() {}

.hook-card-media-left() {}

.hook-card-media-right() {}


// Title
// ========================================================================

.hook-card-title() {}


// Badge
// ========================================================================

.hook-card-badge() when not (@card-badge-border-width = 0) {
    border@{card-badge-border-mode}: @card-badge-border-width solid @card-badge-border;
}


// Hover modifier
// ========================================================================

.hook-card-hover() when not (@card-hover-border-width = 0)
             and not (@internal-card-hover-border-image = ~'') {
    border-image-slice: @internal-card-border-image-slice !important;
    border-image-width: @internal-card-border-image-width !important;
    border-image-repeat: @internal-card-border-image-repeat !important;
}

// Color
.hook-card-hover() when not (@card-hover-border-width = 0) {
    border@{card-hover-border-mode}-color: @card-hover-border;
}

// Image
.hook-card-hover() when not (@card-hover-border-width = 0) and not (@internal-card-hover-border-image = ~'') {
    .svg-fill(@internal-card-hover-border-image, "#000", @card-hover-border, border-image-source);
}


// Style modifiers
// ========================================================================

.hook-card-default() when not (@card-default-border-width = 0)
             and not (@internal-card-default-border-image = ~'') {
    border-image-slice: @internal-card-border-image-slice !important;
    border-image-width: @internal-card-border-image-width !important;
    border-image-repeat: @internal-card-border-image-repeat !important;
}

// Color
.hook-card-default() when not (@card-default-border-width = 0) {
    border@{card-default-border-mode}: @card-default-border-width solid @card-default-border;
}

.hook-card-default-hover() when not (@card-default-border-width = 0) {
    border@{card-default-border-mode}-color: @card-default-hover-border;
}

// Image
.hook-card-default() when not (@card-default-border-width = 0) and not (@internal-card-default-border-image = ~'') {
    .svg-fill(@internal-card-default-border-image, "#000", @card-default-border, border-image-source);
}

.hook-card-default-hover() when not (@card-default-border-width = 0) and not (@internal-card-default-border-image = ~'') {
    .svg-fill(@internal-card-default-border-image, "#000", @card-default-hover-border, border-image-source);
}

.hook-card-default-title() {}

.hook-card-default-header() {}

.hook-card-default-footer() {}

//
// Primary
//

.hook-card-primary() when not (@card-primary-border-width = 0)
             and not (@internal-card-primary-border-image = ~'') {
    border-image-slice: @internal-card-border-image-slice !important;
    border-image-width: @internal-card-border-image-width !important;
    border-image-repeat: @internal-card-border-image-repeat !important;
}

// Color
.hook-card-primary() when not (@card-primary-border-width = 0) {
    border@{card-primary-border-mode}: @card-primary-border-width solid @card-primary-border;
}

.hook-card-primary-hover() when not (@card-primary-border-width = 0) {
    border@{card-primary-border-mode}-color: @card-primary-hover-border;
}

// Image
.hook-card-primary() when not (@card-primary-border-width = 0) and not (@internal-card-primary-border-image = ~'') {
    .svg-fill(@internal-card-primary-border-image, "#000", @card-primary-border, border-image-source);
}

.hook-card-primary-hover() when not (@card-primary-border-width = 0) and not (@internal-card-primary-border-image = ~'') {
    .svg-fill(@internal-card-primary-border-image, "#000", @card-primary-hover-border, border-image-source);
}

.hook-card-primary-title() {}

//
// Secondary
//

.hook-card-secondary() when not (@card-secondary-border-width = 0)
             and not (@internal-card-secondary-border-image = ~'') {
    border-image-slice: @internal-card-border-image-slice !important;
    border-image-width: @internal-card-border-image-width !important;
    border-image-repeat: @internal-card-border-image-repeat !important;
}

// Color
.hook-card-secondary() when not (@card-secondary-border-width = 0) {
    border@{card-secondary-border-mode}: @card-secondary-border-width solid @card-secondary-border;
}

.hook-card-secondary-hover() when not (@card-secondary-border-width = 0) {
    border@{card-secondary-border-mode}-color: @card-secondary-hover-border;
}

// Image
.hook-card-secondary() when not (@card-secondary-border-width = 0) and not (@internal-card-secondary-border-image = ~'') {
    .svg-fill(@internal-card-secondary-border-image, "#000", @card-secondary-border, border-image-source);
}

.hook-card-secondary-hover() when not (@card-secondary-border-width = 0) and not (@internal-card-secondary-border-image = ~'') {
    .svg-fill(@internal-card-secondary-border-image, "#000", @card-secondary-hover-border, border-image-source);
}

.hook-card-secondary-title() {}


// Miscellaneous
// ========================================================================

.hook-card-misc() {}

.hook-card-misc() when not (@card-hover-border-width = 0) {

    .uk-card-hover:not(.uk-card-default):not(.uk-card-primary):not(.uk-card-secondary) { border@{card-hover-border-mode}: @card-hover-border-width solid transparent; }

}


// Inverse
// ========================================================================

@inverse-card-badge-border:                 transparent;

.hook-inverse-card-badge() when not (@card-badge-border-width = 0) {
    border@{card-badge-border-mode}-color: @inverse-card-badge-border;
}
