//
// Component: Off-canvas
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@offcanvas-bar-box-shadow:                              none;


// Bar
// ========================================================================

.hook-offcanvas-bar() when not (@offcanvas-bar-box-shadow = none) {
    box-shadow: @offcanvas-bar-box-shadow;
}


// Close
// ========================================================================

.hook-offcanvas-close() {}


// Overlay
// ========================================================================

.hook-offcanvas-overlay() {}


// Miscellaneous
// ========================================================================

.hook-offcanvas-misc() {}