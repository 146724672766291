//
// Component: Subnav
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@subnav-pill-item-border-radius:                0;


// Component
// ========================================================================

.hook-subnav() {}

.hook-subnav-item() {}

.hook-subnav-item-hover() {}

.hook-subnav-item-active() {}


// Divider modifier
// ========================================================================

.hook-subnav-divider() {}


// Pill modifier
// ========================================================================

.hook-subnav-pill-item() when not (@subnav-pill-item-border-radius = 0) {
    border-radius: @subnav-pill-item-border-radius;
}

.hook-subnav-pill-item-hover() {}

.hook-subnav-pill-item-onclick() {}

.hook-subnav-pill-item-active() {}


// Disabled
// ========================================================================

.hook-subnav-item-disabled() {}


// Miscellaneous
// ========================================================================

.hook-subnav-misc() {}


// Inverse
// ========================================================================

.hook-inverse-subnav-item() {}
.hook-inverse-subnav-item-hover() {}
.hook-inverse-subnav-item-active() {}

.hook-inverse-subnav-divider() {}

.hook-inverse-subnav-pill-item() {}
.hook-inverse-subnav-pill-item-hover() {}
.hook-inverse-subnav-pill-item-onclick() {}
.hook-inverse-subnav-pill-item-active() {}

.hook-inverse-subnav-item-disabled() {}
