//
// Component: Thumbnav
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@thumbnav-item-gradient:                        ~'';


// Component
// ========================================================================

.hook-thumbnav() {}

.hook-thumbnav-item() when not (@thumbnav-item-gradient = ~'') {

    &::after { background-image: @thumbnav-item-gradient; }

}

.hook-thumbnav-item-hover() {}

.hook-thumbnav-item-active() {}


// Miscellaneous
// ========================================================================

.hook-thumbnav-misc() {}


// Inverse
// ========================================================================

@inverse-thumbnav-item-gradient:                ~'';

.hook-inverse-thumbnav-item() when not (@inverse-thumbnav-item-gradient = ~'') {
    &::after { background-image: @inverse-thumbnav-item-gradient; }
}
.hook-inverse-thumbnav-item-hover() {}
.hook-inverse-thumbnav-item-active() {}
