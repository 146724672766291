//
// Component: Label
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@label-border-radius:                           0;


// Component
// ========================================================================

.hook-label() when not (@label-border-radius = 0) {
    border-radius: @label-border-radius;
}


// Color modifiers
// ========================================================================

.hook-label-success() {}

.hook-label-warning() {}

.hook-label-danger() {}


// Miscellaneous
// ========================================================================

.hook-label-misc() {}


// Inverse
// ========================================================================

.hook-inverse-label() {}