//
// Component: Form
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@form-box-shadow:                               none;
@form-focus-box-shadow:                         none;
@form-disabled-box-shadow:                      none;

@form-danger-box-shadow:                        none;
@form-success-box-shadow:                       none;

@form-blank-focus-box-shadow:                   none;

@form-radio-box-shadow:                         none;
@form-radio-focus-box-shadow:                   none;
@form-radio-checked-box-shadow:                 none;


// Component
// ========================================================================

.hook-form() when not (@form-box-shadow = none) {
    box-shadow: @form-box-shadow;
}

.hook-form-single-line() {}

.hook-form-multi-line() {}

.hook-form-focus() when not (@form-focus-box-shadow = @form-box-shadow) {
    box-shadow: @form-focus-box-shadow;
}

.hook-form-disabled() when not (@form-box-shadow = none) {
    box-shadow: @form-disabled-box-shadow;
}


// Style modifiers
// ========================================================================

.hook-form-danger() when not (@form-danger-box-shadow = none) {
    box-shadow: @form-danger-box-shadow;
}

.hook-form-success() when not (@form-success-box-shadow = none) {
    box-shadow: @form-success-box-shadow;
}

.hook-form-blank() when not (@form-box-shadow = none) {
    box-shadow: none;
}

.hook-form-blank-focus() when not (@form-blank-focus-box-shadow = none) {
    box-shadow: @form-blank-focus-box-shadow;
}


// Radio and checkbox
// ========================================================================

.hook-form-radio() when not (@form-radio-box-shadow = none) {
    box-shadow: @form-radio-box-shadow;
}

.hook-form-radio-focus() when not (@form-radio-focus-box-shadow = none) {
    box-shadow: @form-radio-focus-box-shadow;
}

.hook-form-radio-checked() when not (@form-radio-checked-box-shadow = none) {
    box-shadow: @form-radio-checked-box-shadow;
}

.hook-form-radio-checked-focus() {}

.hook-form-radio-disabled() when not (@form-radio-box-shadow = none) {
    box-shadow: none;
}


// Legend
// ========================================================================

.hook-form-legend() {}


// Label
// ========================================================================

.hook-form-label() {}


// Layout
// ========================================================================

.hook-form-stacked-label() {}

.hook-form-horizontal-label() {}


// Icon
// ========================================================================

.hook-form-icon() {}


// Miscellaneous
// ========================================================================

.hook-form-misc() {}


// Inverse
// ========================================================================

@inverse-form-box-shadow:                       none;
@inverse-form-focus-box-shadow:                 none;

@inverse-form-radio-box-shadow:                 none;
@inverse-form-radio-focus-box-shadow:           none;
@inverse-form-radio-checked-box-shadow:         none;

.hook-inverse-form() when not (@form-box-shadow = none) {
    box-shadow: @inverse-form-box-shadow;
}
.hook-inverse-form-focus() when not (@form-focus-box-shadow = none) {
    box-shadow: @inverse-form-focus-box-shadow;
}

.hook-inverse-form-radio() when not (@form-radio-box-shadow = none) {
    box-shadow: @inverse-form-radio-box-shadow;
}
.hook-inverse-form-radio-focus() when not (@form-radio-focus-box-shadow = none) {
    box-shadow: @inverse-form-radio-focus-box-shadow;
}

.hook-inverse-form-radio-checked() when not (@form-radio-checked-box-shadow = none) {
    box-shadow: @inverse-form-radio-checked-box-shadow;
}
.hook-inverse-form-radio-checked-focus() {}

.hook-inverse-form-label() {}

.hook-inverse-form-icon() {}
