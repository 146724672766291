//
// Component: Modal
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@modal-dialog-box-shadow:                       none;


// Component
// ========================================================================

.hook-modal() {}


// Dialog
// ========================================================================

.hook-modal-dialog() when not (@modal-dialog-box-shadow = none) {
    box-shadow: @modal-dialog-box-shadow;
}


// Full
// ========================================================================

.hook-modal-full() {}


// Sections
// ========================================================================

.hook-modal-header() {}

.hook-modal-body() {}

.hook-modal-footer() {}


// Title
// ========================================================================

.hook-modal-title() {}


// Close
// ========================================================================

.hook-modal-close() {}

.hook-modal-close-hover() {}

.hook-modal-close-default() {}

.hook-modal-close-default-hover() {}

.hook-modal-close-outside() {}

.hook-modal-close-outside-hover() {}

.hook-modal-close-full() {}

.hook-modal-close-full-hover() {}


// Miscellaneous
// ========================================================================

.hook-modal-misc() {}