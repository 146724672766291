//
// Component: Variables
//
// ========================================================================

@global-color: #313E4F;
@global-emphasis-color: #19365d;
@global-muted-color: #8a94a8;
@global-link-hover-color: @global-primary-background;
@global-muted-background: #f4f6fb;
@global-primary-background: #773b96;
@global-secondary-background: #4285f4;
//@global-primary-background: #607cb3;
//@global-secondary-background: #2b3145;

//
// Button
//

@button-text-hover-color: #7890bf;
@global-font-family: Roboto;
@global-font-size: 15px;
@global-line-height: 1.6;
@global-2xlarge-font-size: 40px;
@global-xlarge-font-size: 30px;
@global-large-font-size: 22px;
@global-medium-font-size: 18px;
@global-small-font-size: 11px;
@global-primary-font-family: Roboto;
@global-primary-font-weight: 300;
@global-primary-text-transform: inherit;
@global-primary-letter-spacing: inherit;
@global-primary-font-style: inherit;
@global-secondary-font-family: Work Sans;
@global-secondary-font-weight: 500;
@global-secondary-text-transform: uppercase;
@global-secondary-letter-spacing: 1px;
@global-secondary-font-style: inherit;

@global-inverse-color: #fff;
@global-background: #fff;

@global-success-background: #33C15E;
@global-warning-background: #FFAD45;
@global-danger-background: #DE3E3E;
@global-border: #e9e9e9;
@global-border-width: 1px;
@global-border-radius: 0;
@global-small-box-shadow: 0 3px 12px rgba(0,0,0,0.07);
@global-medium-box-shadow: 0 4px 28px rgba(0,0,0,0.07);
@global-large-box-shadow: 0 6px 50px rgba(0,0,0,0.05);
@global-xlarge-box-shadow: 0 28px 50px rgba(0,0,0,0.16);
@global-margin: 20px;
@global-small-margin: 10px;
@global-medium-margin: 40px;
@global-large-margin: 70px;
@global-xlarge-margin: 140px;
@global-gutter: 20px;
@global-small-gutter: 10px;
@global-medium-gutter: 40px;
@global-large-gutter: 70px;
@global-control-height: 40px;
@global-control-small-height: 30px;
@global-control-large-height: 55px;
@global-z-index: 1000;
@base-body-font-weight: 300;
@base-h1-line-height: 1.4;
@base-h5-font-size: 12px;
@base-h4-font-family: @global-primary-font-family;
@base-h4-font-weight: @global-primary-font-weight;
@base-h4-text-transform: @global-primary-text-transform;
@base-h4-letter-spacing: @global-primary-letter-spacing;
@base-code-padding-horizontal: 6px;
@base-code-padding-vertical: 2px;
@base-code-border-width: @global-border-width;
@base-code-border: @global-border;
@base-code-border-radius: 3px;
@base-blockquote-padding-left: @global-medium-gutter;
@base-blockquote-border-mode: -left;
@base-blockquote-border-width: 2px;
@base-blockquote-border: @global-primary-background;
@base-pre-padding: 10px;
@base-pre-background: @global-background;
@base-pre-border-width: @global-border-width;
@base-pre-border: @global-border;
@base-pre-border-radius: 3px;
@inverse-base-code-border: @inverse-global-border;
@inverse-base-blockquote-border: @inverse-global-border;
@inverse-link-text-hover-color: @inverse-global-color;
@heading-small-font-size-m: @heading-medium-font-size-l * 0.78;   // 52px
@heading-medium-font-size-l: 67px;
@heading-line-border-width: ~'calc(0.7px + 0.05em)';
@heading-small-font-weight: 100;
@heading-medium-font-weight: 100;
@heading-large-font-weight: 100;
@heading-xlarge-font-weight: 100;
@heading-2xlarge-font-weight: 100;
@divider-icon-color: @global-primary-background;
@divider-icon-image: "images/divider-icon.svg";
//@internal-divider-icon-image: "images/divider-icon.svg";
@divider-small-width: 45px;
@divider-small-border-width: 2px;
@divider-small-border: @global-primary-background;
@list-bullet-image: "images/list-bullet.svg";
//@internal-list-bullet-image: "images/list-bullet.svg";
@description-list-term-font-size: @global-small-font-size;
@table-striped-row-background: @global-muted-background;
@icon-button-hover-background: @global-background;
@icon-button-hover-color: @global-emphasis-color;
@icon-button-active-background: @icon-button-hover-background;
@icon-button-hover-box-shadow: @global-small-box-shadow;
@inverse-icon-button-hover-background: transparent;
@inverse-icon-button-active-background: transparent;
@inverse-icon-button-active-color: @inverse-global-muted-color;
@inverse-icon-button-hover-box-shadow: 0 3px 12px rgba(0,0,0,0.4);
@inverse-icon-button-active-box-shadow: 0 3px 12px rgba(0,0,0,0.4);
@form-range-thumb-height: 13px;
@form-range-thumb-background: @global-background;
@form-range-track-focus-background: @global-primary-background;
@form-range-thumb-box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.1);
@form-background: @global-background;
@form-focus-background: @global-background;
@form-focus-color: @global-emphasis-color;
@form-radio-background: transparent;
@form-label-font-size: @global-small-font-size;
@form-border: lighten(@global-border, 2%);
@form-border-width: @global-border-width;
@form-focus-border: @global-background;
@form-focus-box-shadow: @global-small-box-shadow;
@form-disabled-border: @global-border;
@form-danger-border: @global-danger-background;
@form-success-border: @global-success-background;
@form-radio-border: darken(@global-border, 5%);
@form-radio-border-width: @global-border-width;
@form-radio-focus-border: @global-primary-background;
@form-radio-checked-border: transparent;
@form-radio-disabled-border: @global-border;
@inverse-form-background: transparent;
@inverse-form-focus-background: transparent;
@inverse-form-radio-background: transparent;
@inverse-form-radio-focus-background: transparent;
@inverse-form-border: @inverse-global-border;
@inverse-form-radio-border: @inverse-global-border;
@inverse-form-radio-focus-border: @inverse-global-primary-background;
@inverse-form-focus-box-shadow: 0 3px 12px rgba(0,0,0,0.4);
@button-font-size: @global-small-font-size;
@button-large-font-size: @global-small-font-size;
@button-padding-horizontal: (@global-medium-gutter - 10);
@button-small-padding-horizontal: @global-gutter;
@button-large-padding-horizontal: @global-medium-gutter;
@button-default-background: @global-background;
@button-default-hover-background: @button-default-background;
@button-default-hover-color: @global-primary-background;
@button-default-active-background: @button-default-background;
@button-primary-hover-background: lighten(@button-primary-background, 7%);
//@button-secondary-background: transparent;
@button-secondary-background: @global-secondary-background;
//@button-secondary-color: @global-secondary-background;
@button-secondary-color: #ffffff;
//@button-secondary-hover-background: transparent;
@button-secondary-hover-background: fade(@global-secondary-background, 70%);
//@button-secondary-hover-color: fade(@global-secondary-background, 70%);
@button-secondary-hover-color: @global-emphasis-color;
@button-secondary-active-background: @global-secondary-background;
@button-secondary-active-color: @global-inverse-color;
//@button-danger-background: transparent;
@button-danger-background: #ff3547;
//@button-danger-color: @global-danger-background;
@button-danger-color: #ffffff;
//@button-danger-hover-background: transparent;
@button-danger-hover-background: fade(@global-danger-background, 70%);
//@button-danger-hover-color: fade(@global-danger-background, 70%);
@button-danger-hover-color: #fff;
@button-danger-active-background: @global-danger-background;
@button-danger-active-color: @global-inverse-color;
@button-text-hover-color: @global-color;
@button-link-hover-color: @global-primary-background;
@button-border-radius: 500px;
@button-small-border-radius: @button-border-radius;
@button-large-border-radius: @button-border-radius;
@button-default-box-shadow: @global-medium-box-shadow;
@button-default-hover-box-shadow: @global-small-box-shadow;
@button-border-width: 1px;
@button-secondary-border: @global-secondary-background;
@button-secondary-hover-border: @button-secondary-border;
@button-secondary-active-border: @button-secondary-border;
@button-danger-border: @global-danger-background;
@button-danger-hover-border: @button-danger-border;
@button-danger-active-border: @button-danger-border;
@button-text-mode: none;
@inverse-button-default-background: fade(@inverse-global-primary-background, 12%);
@inverse-button-default-color: @inverse-global-color;
@inverse-button-default-hover-background: fade(@inverse-global-primary-background, 24%);
@inverse-button-default-hover-color: @inverse-global-emphasis-color;
@inverse-button-default-active-background: @inverse-button-default-background;
@inverse-button-primary-background: @global-primary-background;
@inverse-button-primary-color: @inverse-global-emphasis-color;
@inverse-button-primary-hover-background: lighten(@inverse-button-primary-background, 7%);
@inverse-button-primary-hover-color: @inverse-button-primary-color;
@inverse-button-primary-active-color: fade(@inverse-button-primary-color, 80%);
@inverse-button-secondary-background: transparent;
@inverse-button-secondary-color: @inverse-global-color;
@inverse-button-secondary-hover-background: transparent;
@inverse-button-secondary-hover-color: @inverse-global-emphasis-color;
@inverse-button-secondary-active-background: @inverse-global-primary-background;
@inverse-button-secondary-active-color: @inverse-global-inverse-color;
@inverse-button-secondary-border: @inverse-global-border;
@inverse-button-secondary-hover-border: @inverse-global-border;
@inverse-button-secondary-active-border: @inverse-global-primary-background;
@inverse-button-text-hover-color: @inverse-global-emphasis-color;
@inverse-button-link-color: @inverse-global-color;
@inverse-button-link-hover-color: @inverse-global-emphasis-color;
@card-badge-height: 25px;
@card-badge-padding-horizontal: 11px;
@card-hover-background: @global-background;
@card-default-background: @global-background;
@card-default-hover-background: @card-default-background;
@card-primary-hover-background: darken(desaturate(@card-primary-background, 5%), 7%);
@card-secondary-background: @global-background;
@card-secondary-color: @global-color;
@card-secondary-title-color: @global-emphasis-color;
@card-secondary-hover-background: @card-secondary-background;
@card-secondary-color-mode: dark;
@card-badge-border-radius: 500px;
@card-hover-box-shadow: @global-large-box-shadow;
@card-default-box-shadow: @global-large-box-shadow;
@card-default-hover-box-shadow: @global-medium-box-shadow;
@card-secondary-border-mode: -top;
@card-secondary-border-width: 2px;
@card-secondary-border: @global-primary-background;
@card-secondary-hover-border: @global-primary-background;
@card-secondary-box-shadow: @global-large-box-shadow;
@card-secondary-hover-box-shadow: @global-medium-box-shadow;
@inverse-card-badge-border: @inverse-global-color;
@close-hover-color: @global-emphasis-color;
@totop-color: fadeout(@global-color, 50%);
@totop-hover-color: @global-emphasis-color;
@totop-active-color: @global-primary-background;
@alert-primary-background: lighten(tint(@global-primary-background, 53%), 20%);
@alert-primary-color: darken(@global-primary-background, 6%);
@alert-success-background: lighten(tint(@global-success-background, 45%), 25%);
@alert-success-color: darken(@global-success-background, 12%);
@alert-warning-background: lighten(tint(@global-warning-background, 50%), 15%);
@alert-warning-color: darken(@global-warning-background, 22%);
@alert-danger-background: lighten(tint(@global-danger-background, 50%), 20%);
@label-padding-vertical: 1px;
@label-padding-horizontal: 8px;
@label-border-radius: 2px;
@label-letter-spacing: 0.5px;
@search-color: @global-emphasis-color;
@search-default-background: transparent;
@search-navbar-font-size: @global-medium-font-size;
@search-large-font-size: @global-xlarge-font-size;
@search-toggle-color: @global-muted-color;
@search-toggle-hover-color: @global-emphasis-color;
@search-default-border-width: @global-border-width;
@search-default-border: lighten(@global-border, 2%);
@search-default-input-focus-box-shadow: @global-small-box-shadow;
@inverse-search-default-background: transparent;
@inverse-search-default-focus-background: transparent;
@inverse-search-default-border: @inverse-global-border;
@inverse-search-default-input-focus-box-shadow: 0 3px 12px rgba(0,0,0,0.4);
@dropdown-background: @global-background;
@dropdown-padding: 25px;
@dropdown-nav-item-hover-color: @global-emphasis-color;
@dropdown-nav-header-color: @global-primary-background;
@dropdown-nav-sublist-item-hover-color: @global-primary-background;
@dropdown-box-shadow: @global-medium-box-shadow;
@dropdown-nav-font-size: @global-small-font-size;
@dropdown-nav-subtitle-font-size: 12px;
@dropdown-nav-subtitle-color: @global-muted-color;
@dropdown-nav-subtitle-font-family: @global-font-family;
@dropdown-nav-subtitle-font-weight: normal;
@dropdown-nav-subtitle-text-transform: none;
@dropbar-padding-top: 0;
@dropbar-padding-bottom: 25px;
@dropbar-background: @global-background;
@dropbar-top-box-shadow: 0 20px 20px -20px fade(@global-secondary-background, 7%);
@dropbar-bottom-box-shadow: 0 -20px 20px -20px fade(@global-secondary-background, 7%);
@dropbar-left-box-shadow: 20px 0 20px -20px fade(@global-secondary-background, 7%);
@dropbar-right-box-shadow: -20px 0 20px -20px fade(@global-secondary-background, 7%);
@modal-background: fadeout(@global-secondary-background, 60%);
@modal-dialog-box-shadow: @global-large-box-shadow;
@slider-container-margin-bottom: -56px;
@slider-container-margin-left: -35px;
@slider-container-margin-right: -35px;
@slider-container-margin-top: -30px;
@offcanvas-bar-background: @global-background;
@offcanvas-bar-color-mode: dark;
@offcanvas-bar-box-shadow: @global-large-box-shadow;
@nav-default-font-size: 12px;
@nav-default-item-hover-color: @global-emphasis-color;
@nav-default-subtitle-font-size: 13px;
@nav-default-sublist-item-hover-color: @global-emphasis-color;
@nav-primary-item-hover-color: @global-emphasis-color;
@nav-primary-subtitle-font-size: @global-small-font-size;
@nav-primary-sublist-item-hover-color: @global-emphasis-color;
@nav-secondary-font-size: 12px;
@nav-secondary-line-height: @global-line-height;
@nav-secondary-subtitle-color: @global-color;
@nav-secondary-subtitle-hover-color: @global-emphasis-color;
@nav-secondary-subtitle-font-size: 13px;
@nav-secondary-sublist-item-hover-color: @global-emphasis-color;
@nav-secondary-sublist-item-active-color: @global-emphasis-color;
@nav-dividers-margin-top: 8px;
@nav-default-subtitle-color: @global-muted-color;
@nav-default-subtitle-font-family: @global-font-family;
@nav-default-subtitle-font-weight: 300;
@nav-default-subtitle-text-transform: none;
@nav-default-subtitle-letter-spacing: normal;
@nav-primary-subtitle-color: @global-muted-color;
@nav-primary-subtitle-font-family: @global-font-family;
@nav-primary-subtitle-font-weight: 300;
@nav-primary-subtitle-text-transform: none;
@nav-primary-subtitle-letter-spacing: normal;
@nav-secondary-item-padding-horizontal: 15px;
@nav-secondary-item-padding-vertical: 15px;
@nav-secondary-item-hover-background: @global-muted-background;
@nav-secondary-item-active-background: @nav-secondary-item-hover-background;
@nav-secondary-subtitle-font-family: @global-font-family;
@nav-secondary-subtitle-font-weight: 300;
@nav-secondary-subtitle-letter-spacing: normal;
@nav-secondary-subtitle-text-transform: none;
@inverse-nav-secondary-subtitle-hover-color: @inverse-global-emphasis-color;
@inverse-nav-secondary-item-hover-background: @inverse-global-muted-background;
@inverse-nav-secondary-item-active-background: @inverse-nav-secondary-item-hover-background;
@navbar-background: @global-background;
@navbar-nav-gap: 0px;
@navbar-nav-item-padding-horizontal: 15px;
@navbar-nav-item-color: @global-muted-color;
@navbar-nav-item-font-size: 12px;
@navbar-nav-item-hover-color: @global-emphasis-color;
@navbar-item-padding-horizontal: 0;
@navbar-toggle-color: @global-muted-color;
@navbar-toggle-hover-color: @global-emphasis-color;
@navbar-subtitle-font-size: 12px;
@navbar-dropdown-shift-margin: -(@navbar-dropdown-padding - @navbar-nav-item-padding-horizontal);
@navbar-dropdown-background: @global-background;
@navbar-dropdown-padding: 25px;
@navbar-dropdown-large-shift-margin: -(@navbar-dropdown-large-padding - @navbar-nav-item-padding-horizontal);
@navbar-dropdown-dropbar-shift-margin: @navbar-nav-item-padding-horizontal;
@navbar-dropdown-dropbar-large-shift-margin: @navbar-nav-item-padding-horizontal;
@navbar-dropdown-nav-subtitle-font-size: 12px;
@navbar-nav-gap-m: 0px;
@navbar-nav-item-line-mode: true;
@navbar-nav-item-line-position-mode: top;
@navbar-nav-item-line-hover-background: transparent;
@navbar-nav-item-line-onclick-background: transparent;
@navbar-nav-item-line-onclick-height: 2px;
@navbar-nav-item-line-active-height: 2px;
@navbar-dropdown-nav-font-size: @global-small-font-size;
@navbar-sticky-box-shadow: @global-small-box-shadow;
@navbar-dropdown-box-shadow: @global-medium-box-shadow;
@navbar-subtitle-color: @global-muted-color;
@navbar-subtitle-font-family: @global-font-family;
@navbar-subtitle-font-weight: 400;
@navbar-subtitle-text-transform: none;
@navbar-subtitle-letter-spacing: normal;
@navbar-primary-nav-item-padding-horizontal: 25px;
@navbar-primary-nav-item-padding-horizontal-m: 25px;
@navbar-primary-nav-item-font-size: @global-font-size;
@navbar-dropdown-nav-subtitle-color: @global-muted-color;
@navbar-dropdown-nav-subtitle-font-family: @global-font-family;
@navbar-dropdown-nav-subtitle-font-weight: 400;
@navbar-dropdown-nav-subtitle-text-transform: none;
@navbar-dropdown-nav-subtitle-letter-spacing: normal;
@inverse-navbar-nav-item-line-hover-background: transparent;
@inverse-navbar-nav-item-line-onclick-background: @inverse-global-primary-background;
@inverse-navbar-nav-item-line-active-background: @inverse-global-primary-background;
@subnav-item-hover-color: @global-emphasis-color;
@subnav-divider-border-height: 0.9em;
@subnav-pill-item-padding-vertical: 8px;
@subnav-pill-item-padding-horizontal: 20px;
@subnav-pill-item-hover-background: @global-background;
@subnav-pill-item-hover-color: @global-emphasis-color;
@subnav-pill-item-onclick-color: @global-emphasis-color;
@subnav-item-font-size: @global-small-font-size;
@subnav-pill-item-border-radius: 500px;
@subnav-pill-item-hover-box-shadow: @global-small-box-shadow;
@subnav-pill-item-onclick-box-shadow: @global-medium-box-shadow;
@breadcrumb-item-active-color: @global-emphasis-color;
@breadcrumb-divider-color: darken(@global-border, 5%);
@pagination-margin-horizontal: 10px;
@pagination-item-padding-vertical: 3px;
@pagination-item-padding-horizontal: 6px;
@pagination-item-hover-color: @global-emphasis-color;
@pagination-item-active-color: @global-emphasis-color;
@pagination-item-min-width: 26px;
@pagination-item-line-height: 20px;
@pagination-item-font-size: @global-small-font-size;
@pagination-item-letter-spacing: 0;
@pagination-item-hover-background: @global-background;
@pagination-item-border-radius: 500px;
@pagination-item-hover-box-shadow: @global-small-box-shadow;
@tab-item-padding-horizontal: 20px;
@tab-item-padding-vertical: 10px;
@tab-item-font-size: @global-small-font-size;
@tab-item-line-height: 20px;
@dotnav-margin-horizontal: 14px;
@dotnav-item-width: 8px;
@dotnav-item-hover-background: @global-primary-background;
@dotnav-item-active-background: @global-primary-background;
@inverse-dotnav-item-hover-background: @inverse-global-emphasis-color;
@inverse-dotnav-item-active-background: @inverse-dotnav-item-hover-background;
@thumbnav-item-background: transparent;
@thumbnav-item-gradient: linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,0.4));
@inverse-thumbnav-item-background: transparent;
@inverse-thumbnav-item-gradient: linear-gradient(180deg, rgba(0,0,0,0), rgba(0,0,0,0.4));
@iconnav-item-hover-color: @global-emphasis-color;
@iconnav-item-active-color: @global-emphasis-color;
@iconnav-item-font-size: @global-font-size;
@text-lead-font-size: 20px;
@text-small-font-size: 12px;
@text-small-line-height: 1.4;
@inverse-global-color: fade(@global-inverse-color, 70%);
@inverse-global-muted-color: fade(@global-inverse-color, 50%);
@inverse-global-inverse-color: @global-emphasis-color;
@inverse-global-primary-background: @global-inverse-color;
@inverse-global-muted-background: fade(@global-inverse-color, 10%);
@inverse-global-border: fade(@global-inverse-color, 8%);